<template>
  <v-row>
    <v-col cols="12">
      <v-card elevation="0">
        <v-row class="pa-3">
          <v-col cols="12" sm="6">
            <div>Mata Pelajaran : {{ subject }}</div>
            <div>Kelas : {{ className }}</div>
            <div>Judul : {{ title }}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <div v-if="readonly" class="d-flex flex-column align-md-end">
              <div :class="!correction && 'font-weight-bold'">
                {{ correction ? participant.name : `Durasi ${duration} Menit` }}
              </div>
              <div class="font-weight-bold">
                {{ correction ? `${totalScore} Poin` : date }}
              </div>
            </div>
            <div v-else class="d-flex flex-column">
              <div class="d-flex justify-md-end mb-2">
                <span class="font-weight-bold mr-5">Batas Waktu</span>
                <span class="font-weight-bold">
                  {{ duration ? countDown : "00:00" }}
                </span>
              </div>
              <div class="d-flex justify-md-end">
                <v-btn
                  class="primary"
                  depressed
                  dark
                  @click="dialogSave = true"
                >
                  Selesai
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col v-if="!readonly" cols="12">
            <v-divider />
          </v-col>
          <v-col v-if="!readonly" cols="12">
            <v-progress-linear
              v-model="valueDeterminate"
              color="amber"
              class="mb-3"
              height="10"
              rounded
            />
            <div class="grey pa-1 rounded" id="numbering-question">
              <v-chip
                v-for="(item, index) in questionList"
                :key="index"
                :class="styleNumbering(index)"
                :dark="isDark(index)"
                class="ma-1"
                label
                @click="questionIndex = index"
              >
                {{ index + 1 }}
              </v-chip>
            </div>
          </v-col>
          <v-col v-if="!readonly" cols="12" sm="6" class="d-flex align-center">
            <div class="color-info" style="background-color: #e3b105" />
            <span class="mr-4 ml-1">Terjawab</span>
            <div class="color-info mr-1" style="background-color: #4886ff" />
            <div>Ditandai</div>
          </v-col>
          <v-col
            v-if="!readonly"
            cols="12"
            sm="6"
            class="d-flex justify-md-end"
          >
            <v-btn
              style="background-color: #4886ff"
              dark
              depressed
              @click="questionMark()"
            >
              Tandai Soal
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col v-if="questionList.length" cols="12" id="question">
      <v-card class="pa-3 d-flex" elevation="0">
        <div class="mr-5">{{ questionList[questionIndex].no }}.</div>
        <v-row no-gutters>
          <v-col cols="12" class="d-flex">
            <span v-html="questionList[questionIndex].question" />
            <v-spacer />
            <span class="font-weight-bold">
              {{ questionList[questionIndex].point }} Poin
            </span>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-if="questionList[questionIndex].type === 'option'"
              v-model="modelAnswer"
              :readonly="readonly"
              @change="postAnswer(questionList[questionIndex])"
            >
              <v-row
                v-for="(item, index) in questionList[questionIndex].options"
                :key="index"
                class="d-flex"
              >
                <v-radio :value="item.key" />
                <span class="mr-2"> {{ item.key }}. </span>
                <div class="d-flex flex-column">
                  <span v-html="item.description" />
                  <v-img
                    v-if="item.file"
                    :src="item.file"
                    max-height="100"
                    max-width="100"
                    style="cursor: pointer"
                    @click="openImg(item.file)"
                  />
                </div>
              </v-row>
            </v-radio-group>
            <quill-editor
              v-else
              v-model="modelAnswer"
              ref="myQuillEditor"
              :options="editorOption"
              :disabled="readonly"
              class="mb-4"
              aria-placeholder="Penjelasan Soal"
              @blur="postAnswer(questionList[questionIndex])"
            />
          </v-col>
          <v-col v-if="correction" cols="12" class="my-2">
            <v-divider />
          </v-col>
          <v-col v-if="correction" cols="12" class="d-flex flex-column mb-2">
            <span class="subtitle">Kunci Jawaban</span>
            <span
              v-html="questionList[questionIndex].key"
              class="font-weight-bold"
            />
          </v-col>
          <v-col v-if="correction" cols="12">
            <v-divider />
          </v-col>
          <v-col v-if="correction" cols="12" class="d-flex flex-column mt-2">
            <span> Poin untuk Siswa </span>
            <v-form ref="form" lazy-validation>
              <v-text-field
                v-model="questionList[questionIndex].score"
                :rules="[
                  (v) =>
                    v <= questionList[questionIndex].point ||
                    'Poin siswa tidak boleh melebihi poin soal',
                ]"
                :loading="loadingScore"
                :disabled="isStudent"
                :style="isStudent ? 'max-width: 100px' : 'max-width: 250px'"
                placeholder="Poin Siswa"
                type="number"
                class="mt-2 select-75"
                outlined
                dense
                @blur="editScore"
                @keydown.enter="editScore"
              />
              <div
                v-if="questionList[questionIndex].type === 'option'"
                class="d-flex flex-column mb-2"
              >
                <span>Jawaban Siswa</span>
                <span class="font-weight-bold">
                  {{
                    questionList[questionIndex].key ===
                    questionList[questionIndex].answer
                      ? "Benar"
                      : "Salah"
                  }}
                </span>
              </div>
            </v-form>
          </v-col>
          <v-col v-if="correction" cols="12" class="mb-2">
            <v-divider />
          </v-col>
          <v-col v-if="correction" cols="12" class="d-flex flex-column">
            <span class="mb-2"> Penjelasan Soal </span>
            <span
              v-html="questionList[questionIndex].description"
              class="grey pa-3"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" class="mt-n3">
      <v-card elevation="0">
        <v-row class="pa-2">
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12" sm="4">
            <v-btn
              :disabled="questionIndex === 0"
              color="blue-grey darken"
              outlined
              depressed
              @click="previous()"
            >
              Sebelumnya
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4">
            <span class="font-weight-bold">
              Pertanyaan {{ questionIndex + 1 }} dari
              {{ questionList.length }} Soal
            </span>
          </v-col>
          <v-col cols="12" sm="4" class="d-flex justify-sm-end">
            <v-btn
              :disabled="isLastQuestion()"
              :dark="!isLastQuestion()"
              class="indigo darken-4"
              depressed
              @click="next()"
            >
              Selanjutnya
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-dialog v-model="dialogSave" width="300" persistent>
      <v-card>
        <v-card-title class="text-h5 grey"> Perhatian! </v-card-title>
        <v-card-text>
          Apakah anda yakin ingin mengakhiri E-Test tersebut?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="indigo" outlined @click="dialogSave = false">
            Kembali
          </v-btn>
          <v-btn
            :loading="loadingEtest"
            class="indigo darken-4"
            dark
            text
            @click="doneEtest()"
          >
            Selesai
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSuccess" width="300" persistent>
      <v-card>
        <v-card-title class="text-h5 grey"> Selamat! </v-card-title>
        <v-card-text> Anda berhasil menyelesaikan E-Test </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="indigo"
            dark
            outlined
            @click="$router.push({ name: 'Dashboard' })"
          >
            Beranda
          </v-btn>
          <v-btn
            class="indigo darken-4"
            dark
            text
            @click="$router.push({ name: 'EtestHistory' })"
          >
            Daftar Nilai
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loadingEtest" z-index="100">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-row>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  detailEtest,
  startEtest,
  getEtestStudent,
  finishEtest,
  answerEtest,
  etestCorrection,
  updateScore,
} from "@/api/etest";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

export default {
  props: {
    readonly: Boolean,
    correction: Boolean,
  },
  components: {
    quillEditor,
  },
  created() {
    const query = this.$route.query;
    this.startEtest();
    this.getCorrection(query.person, query.etest);
    this.getDataById(query.id);
  },
  mounted() {
    this.interval();
  },
  computed: {
    countDown() {
      return this.duration.format("h:mm:ss");
    },
    modelAnswer: {
      get() {
        return this.readonly && !this.correction
          ? this.questionList[this.questionIndex].key
          : this.questionList[this.questionIndex].answer;
      },
      set(value) {
        this.questionList[this.questionIndex].answer = value;
      },
    },
    valueDeterminate() {
      return (
        (this.questionList.filter((e) => e.answer !== null).length * 100) /
        this.questionList.length
      );
    },
    isStudent() {
      return this.$store.getters.g_roles === "STUDENT";
    },
    totalScore() {
      return this.questionList.reduce(
        (acc, curr) => acc + parseInt(curr.score),
        0
      );
    },
  },
  data() {
    return {
      loadingEtest: false,
      dialogSave: false,
      dialogSuccess: false,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
        },
      },
      questionIndex: 0,
      questionList: [],
      subject: "",
      className: "",
      title: "",
      endTime: null,
      duration: null,
      loadingScore: false,
      date: "",
      participant: {
        name: "",
        point: "",
      },
    };
  },
  methods: {
    isDark(index) {
      return index === this.questionIndex || this.questionList[index].mark;
    },
    questionMark() {
      this.questionList[this.questionIndex].mark =
        !this.questionList[this.questionIndex].mark;
    },
    scroll() {
      document
        .querySelector("#question")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    },
    next() {
      if (!this.isLastQuestion()) {
        this.questionIndex += 1;
        this.scroll();
      }
    },
    isLastQuestion() {
      return this.questionIndex === this.questionList.length - 1;
    },
    previous() {
      this.questionIndex -= 1;
      this.scroll();
    },
    goToQuestion(item) {
      console.log(item);
    },
    styleNumbering(index) {
      const padding = `${index + 1}`.length === 1 ? "px-4 align-center" : "";
      const color = this.questionList[index].answer
        ? "primary"
        : this.questionIndex === index
        ? "blue-grey lighten-4"
        : this.questionList[index].mark
        ? "light-blue darken-1"
        : "white";
      return padding.concat(" ").concat(color);
    },
    getDataById(id) {
      if (this.readonly && !this.correction) {
        detailEtest({ id: id }).then((res) => {
          if (res.data.code) {
            const data = res.data.data;
            this.subject = data.subject;
            this.className = data.class_name;
            this.title = data.title;
            this.duration = parseInt(data.duration);
            this.date = `${this.dateFromEpoch(
              data.start_time
            )} - ${this.dateFromEpoch(data.end_time)}`;
            this.questionList = data.questions;
          }
        });
      }
    },
    getCorrection(person, etest) {
      if (this.readonly && this.correction) {
        this.loadingEtest = true;
        etestCorrection({ person: person, e_test: etest })
          .then((res) => {
            if (res.data.code) {
              const data = res.data.data;
              this.subject = data.e_test.subject;
              this.className = data.e_test.class_name;
              this.title = data.e_test.title;
              this.participant.name = data.participant.name;
              this.questionList = data.questions;
            }
          })
          .finally(() => (this.loadingEtest = false));
      }
    },
    dateFromEpoch(date) {
      return moment
        .unix(parseInt(date.toString().substr(0, 10)))
        .format("DD/MM/YYYY");
    },
    openImg(file) {
      window.open(file, "_blank").focus();
    },
    startEtest() {
      const idEtest = this.$route.query.id;
      if (!this.readonly && idEtest !== null) {
        this.loadingEtest = true;
        startEtest({ id: idEtest })
          .then((res) => {
            if (res.data.code) {
              getEtestStudent({ id: idEtest }).then((resEtest) => {
                if (resEtest.data.code) {
                  const data = resEtest.data.data;
                  const etest = data.e_test;
                  this.endTime = etest.end_time;
                  this.subject = etest.subject;
                  this.title = etest.title;
                  this.className = etest.class_name;
                  this.questionList = data.questions;
                  if (!this.duration)
                    this.duration = moment.duration(
                      etest.duration_remaining,
                      "minutes"
                    );
                } else {
                  this.snackBar(false, resEtest.data.message);
                  this.$router.push({ name: "Dashboard" });
                }
              });
            } else {
              this.snackBar(false, res.data.message);
              this.$router.push({ name: "Dashboard" });
            }
          })
          .finally(() => (this.loadingEtest = false));
      }
    },
    interval() {
      const idEtest = this.$route.query.id;
      if (!this.readonly && idEtest !== null) {
        let countDown = setInterval(() => {
          const endDate = moment.unix(
            parseInt(`${this.endTime}`.substring(0, 10))
          );
          const currentDate = moment();
          let hours;
          let minute;
          let seconds;

          if (this.duration) {
            const oneSeconds = moment.duration({ seconds: 1 });
            this.duration = moment
              .duration(this.duration, "minutes")
              .subtract(oneSeconds);
            minute = this.duration?.minutes();
            hours = this.duration?.hours();
            seconds = this.duration?.seconds();
            if (!idEtest) clearInterval(countDown);
            // validate duration and current clock
            if (
              (hours === 0 && minute === 0 && seconds === 0) ||
              endDate.diff(currentDate, "minutes") < 0
            ) {
              clearInterval(countDown);
              this.doneEtest();
            }
          }
        }, 1000);
      }
    },
    doneEtest() {
      this.loadingEtest = true;
      finishEtest({ id: this.$route.query.id })
        .then((res) => {
          if (res.data.code) {
            this.snackBar(true, "Berhasil menyimpan Etest");
          } else {
            this.snackBar(false, "Gagal menyimpan Etest");
          }
        })
        .catch(() => this.snackBar(false, "Gagal menyimpan Etest"))
        .finally(() => {
          this.loadingEtest = false;
          this.dialogSave = false;
          this.dialogSuccess = true;
        });
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    postAnswer(item) {
      if (!this.correction || !this.readonly) {
        answerEtest({ id: item.id, answer: item.answer })
          .then((res) => {
            if (!res.data.code) {
              this.snackBar(false, "Jawaban harus diisi");
            }
          })
          .catch(() => this.snackBar(false, "Gagal menjawab Etest"));
      }
    },
    editScore(e) {
      e.preventDefault();
      if (!this.isStudent && this.$refs.form.validate()) {
        this.loadingScore = true;
        const data = this.questionList[this.questionIndex];
        updateScore({ id: data.id, score: data.score })
          .then((res) => {
            if (res.data.code) {
              this.snackBar(true, "Berhasil edit poin siswa");
            } else {
              this.snackBar(false, "Gagal edit poin siswa");
            }
          })
          .finally(() => (this.loadingScore = false));
      }
    },
  },
};
</script>

<style lang="scss">
#numbering-question {
  max-height: 90px;
  overflow: auto;
}
.color-info {
  border-radius: 6px;
  width: 25px;
  height: 25px;
}
</style>
